import { APIPrototype } from './APIPrototype';

const RKDAPI = Object.create(APIPrototype);
let BASEURL = "https://endpoint.rkdplatform.com";
let TESTURL = "http://localhost:5000";
if (process.env.NODE_ENV === 'development') BASEURL = '';

// ++ AUTH ++
RKDAPI.login = function (loginData) {
  return fetch(`${BASEURL}/login`, {
    method: 'POST',
    headers: this.getJSONHeaders(),
    body: JSON.stringify(loginData),
  }).then(this.handleResponse);
};
RKDAPI.resetPassword = function (resetData) {
  return fetch(`${BASEURL}/reset-password`, {
    method: 'POST',
    headers: this.getJSONHeaders(),
    body: JSON.stringify(resetData),
  }).then(this.handleResponse);
};

// ++ Product ++
RKDAPI.fetchProfile = function () {
  return fetch(`${BASEURL}/perfil`, {
    method: 'GET',
    headers: this.getAuthJSONHeaders()
  }).then(this.handleResponse);
};

RKDAPI.fetchFile = function () {
  return fetch(`${BASEURL}/get-file`, {
    method: 'GET',
    headers: this.getAuthJSONHeaders()
  }).then(this.handleResponse);
};

RKDAPI.fetchProductInfo = function () {
  return fetch(`${BASEURL}/algoritmo`, {
    method: 'GET',
    headers: this.getAuthJSONHeaders()
  }).then(this.handleResponse);
};

// ++ Files ++
RKDAPI.fetchFiles = function () {
  return fetch(`${BASEURL}/files`, {
    method: 'GET',
    headers: this.getAuthJSONHeaders()
  }).then(this.handleResponse);
};
RKDAPI.deleteFile = function ({nombre}) {
  return fetch(`${BASEURL}/files`, {
    method: 'DELETE',
    headers: this.getAuthJSONHeaders(),
    body: JSON.stringify({nombre}),
  }).then(this.handleResponse);
};
RKDAPI.uploadFile = function ({ file }) {
  return fetch(`${BASEURL}/files`, {
    method: 'POST',
    headers: this.getAuthHeaders(),
    body: file,
  }).then(this.handleResponse);
};

// ++ Jobs ++
RKDAPI.runJob = function () {
  return fetch(`${BASEURL}/job`, {
    method: 'POST',
    headers: this.getAuthJSONHeaders()
  }).then(this.handleResponse);
};
RKDAPI.fetchJobState = function (jobid) {
  return fetch(`${BASEURL}/job/status?jobid=${jobid}`, {
    method: 'GET',
    headers: this.getAuthJSONHeaders(),
  }).then(this.handleResponse);
};
RKDAPI.fetchJobsRecords = function () {
  return fetch(`${BASEURL}/job`, {
    method: 'GET',
    headers: this.getAuthJSONHeaders(),
  }).then(this.handleResponse);
};

// ++ Dashboard ++
RKDAPI.fetchDashboardParams = function () {
  return fetch(`${BASEURL}/dashboard`, {
    method: 'GET',
    headers: this.getAuthJSONHeaders(),
  }).then(this.handleResponse);
};

export { RKDAPI };
