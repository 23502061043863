import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withAPI } from 'services/API';

const AuthRoute = ({api, children, ...rest}) => {
  return (
    <Route {...rest}>
      {api.authToken
        ? children
        : <Redirect to="/login" />
      }
    </Route>
  );
}

export default  withAPI(AuthRoute);
