import React from 'react';
import { Button as AntButton } from 'antd';


import './Button.scss';

const Button = ({
  type = "default",
  className = "",
  size = "large",
  children,
  ...props
}) => (
  <AntButton
    {...props}
    className={`rkdbutton rkdbutton-${type} ${className}`}
    size={size}
  >
    {children}
  </AntButton>
 );

export default Button;
