import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
import { APIContext, RKDAPI } from 'services/API';
import { MainLayout } from 'modules/core/layouts';
import { localToken } from 'utils';
import AuthRoute from './AuthRoute/AuthRoute';
import { Login } from 'modules/login';
import { Algoritmo, Perfil } from 'modules/home';
import { Dashboard, Datos, Predecir } from 'modules/flow';

const authAPI = RKDAPI.withAuthUser(localToken.get());

const App = () => {
  return (
    <ConfigProvider locale={esES}>
        <APIContext.Provider value={{ api: authAPI }}>
          <Switch>
            <Route exact path="/login">
              <Login/>
            </Route>
            <AuthRoute exact path="/algorithm">
              <MainLayout>
                <Algoritmo />
              </MainLayout>
            </AuthRoute>
            <AuthRoute exact path="/profile">
              <MainLayout>
                <Perfil />
              </MainLayout>
            </AuthRoute>
            <AuthRoute exact path="/data">
              <MainLayout>
                <Datos />
              </MainLayout>
            </AuthRoute>
            <AuthRoute exact path="/predict">
              <MainLayout>
                <Predecir />
              </MainLayout>
            </AuthRoute>
            <AuthRoute exact path="/dashboard">
              <MainLayout>
                <Dashboard />
              </MainLayout>
            </AuthRoute>
            <Redirect to={`/algorithm`} />
          </Switch>
        </APIContext.Provider>
    </ConfigProvider>
  );
};

export {
  App
};
