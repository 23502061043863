import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Layout, message, Spin } from 'antd';
import { withAPI } from 'services/API';
import { Button } from 'modules/core/components';

import './Perfil.scss';
import { localToken } from 'utils';
const {Content} = Layout;

const Details = ({ data }) => {
  return (
    <Spin spinning={!data}>
      <div className="perfil-data">
        <div><span>Nombre y apellido:</span>{data?.nombre_completo}</div>
        <div><span>Contraseña:</span>{data?.password}</div>
        <div><span>Industria:</span>{data?.industria}</div>
        <div className="perfil-data-footer">
          <div>¿Querés modificar tus datos?</div>
          Escribinos a <a href="mailto:powersuite@rockingdata.com.ar">powersuite@rockingdata.com.ar</a>
        </div>
      </div>
    </Spin>
  )
};
const Perfil = ({api}) => {
  const history = useHistory();
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    api.fetchProfile()
    .then(r=> r.body && setProfile(JSON.parse(r.body)))
    .catch(() => { message.error("No se pudo encontrar el perfil") });
  }, [api]);
  const onLogout = () => {
    localToken.remove();
    history.push("/login");
  };
  return (
    <Layout className="perfil-layout">
      <Content>
        <div className="title">Perfil</div>
        <Details data={profile}/>
        <Button
          className="perfil-logout-button"
          type="tertiary"
          onClick={onLogout}
        >Cerrar sesión</Button>
      </Content>
    </Layout>
  );
};

export default withAPI(Perfil);
