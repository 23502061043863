import React from 'react';
import { Modal as AntModal } from 'antd';
import { RKDIcons } from 'modules/core/components';

import './Modal.scss';


const Modal = ({children, className="", ...props}) => {
  return (
   <AntModal
    {...props}
    className={`rkdmodal ${className}`}
    width="450px"
    footer={null}
    closeIcon={<RKDIcons.Close/>}
   >
     {children}
  </AntModal>
 )
};

export default Modal;
