import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message, Space } from 'antd';
import { withAPI } from 'services/API';
import { Button } from 'modules/core/components';
import Lottie from 'react-lottie-player';
import robotIdleAnimation from 'assets/animations/robot-serio.json'
import robotSuccessAnimation from 'assets/animations/robot-corazones.json'
import robotErrorAnimation from 'assets/animations/robot-llora.json'
import predictingAnimation from 'assets/animations/predict-sphere.json'

import './PredictRobot.scss';
import { useInterval } from 'utils';

const STEPS = {
  IDLE: "idle",
  PREDICTING: "predicting",
  SUCCESS: "success",
  ERROR: "error"
};

const PredictRobot = ({api, onSuccess}) => {
  const history = useHistory();
  const [startingJob, setStartingJob] = useState(false);
  const [job, setJob] = useState(null);
  const [polling, setPolling] = useState(false);
  const [step, setStep] = useState(STEPS.IDLE);

  const checkState = () => {
    api.fetchJobState(job)
    .then(r=>{
      const status = r.body.status;
      if(status==='ok'){
        setPolling(false);
        setStep(STEPS.SUCCESS);
        onSuccess && onSuccess();
      }
      if(status==='error'){
        setPolling(false);
        setStep(STEPS.ERROR);
      }
    })
    .catch(() => {
      setStep(STEPS.IDLE);
      setPolling(false);
      message.error('Hubo un error al recuperar el estado de la predicción.');
    });
  };
  useInterval(checkState, polling ? 2000 : null);

  const getFile = () => {
    api.fetchFile()
    .then(response => {window.open(response.body.url)})
    //.then(data => console.log(data))
    //window.location.href = response.body.url
    .catch(err => console.log(err));
  };

  const predict = async () => {
    setStartingJob(true);
    try {
      const {body} = await api.runJob();
      const job = JSON.parse(body).jobid;
      setJob(job);
      setStartingJob(false);
      setStep(STEPS.PREDICTING);
      setPolling(true);
    } catch(e) {
      setStartingJob(false);
      message.error('Hubo un error al inicializar la predicción.');
    };
  };

  const renderData = {
    [STEPS.IDLE]: {
      animation: robotIdleAnimation,
      footer: ()=><Button onClick={predict} loading={startingJob}>Predecir datos</Button>
    },
    [STEPS.PREDICTING]: {
      animation: predictingAnimation,
      footer: ()=><div className="predicting-text">Estamos prediciendo tus datos...</div>
    },
    [STEPS.ERROR]: {
      animation: robotErrorAnimation,
      footer: ()=>(<>
          <div className="predict-result-text predict-result-error">
            Ocurrió un error en la predicción
           </div>
          <div className="predict-result-detail">
            Tuvimos un problema para procesar los datos, en breve <span>nos vamos a comunicar con vos vía mail</span> para continuar con el proceso.
          </div>
          <Button onClick={()=>setStep(STEPS.IDLE)}>Entendido</Button>
      </>)
    },
    [STEPS.SUCCESS]: {
      animation: robotSuccessAnimation,
      footer: ()=>(<>
          <div className="predict-result-text predict-result-success">
            ¡La predicción se está ejecutando, recibiras un correo cuando finalice!
           </div>
          <div className="predict-result-detail">
            Encontrarás los gráficos de la predicción en “Dashboard”.
          </div>
          <Space size="middle">
            <Button onClick={getFile()}type="secondary">Descargar CSV</Button>
            <Button onClick={()=>history.push("/dashboard")}>Ir al dashboard</Button>
          </Space>
      </>)
    }
  }

  const Footer = renderData[step].footer;
  const animation = renderData[step].animation;
  return (
    <div className="predict-robot">
      <Lottie
        animationData={animation}
        play
        style={{ width: 200, height: 200 }}
      />
      <Footer/>
    </div>
  )
};

export default withAPI(PredictRobot);
