import React, { useState } from 'react';
import { Button } from 'modules/core/components';

import './Card.scss';

const Card = ({
  title =null,
  icon: Icon = null,
  body = "",
  length = 150,
  className = ""
}) => {
  const [expanded, setExpanded] = useState(false);
  const isBodyLarge = body?.length > length;
  const minBody = isBodyLarge ? `${body?.substring(0, length)}...` : body;
  return (
    <div className={`rkdcard ${className}`}>
      <div className="rkdcard-title">
        <div className="rkdcard-icon">{Icon}</div>
        {title ?? ""}
       </div>
      <div className="rkdcard-body" >{expanded ? body : minBody}</div>
      {isBodyLarge &&
        <Button
          size="default"
          type="tertiary"
          onClick={()=>setExpanded(prev=>!prev)}
        >
          {expanded ? "Ver menos": "Ver más"}
        </Button>
      }
    </div>
  );
};

export default Card;
