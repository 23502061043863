import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { withAPI } from 'services/API';
import { Layout, message, Spin } from 'antd';
import { Button, Card, Table, Alert, RKDIcons } from 'modules/core/components';
import UploadModal from './UploadModal/UploadModal';

import './Datos.scss';
const {Content} = Layout;

const columns = (onDelete) => {
  const columns = [];
  columns.push(
    {
      title: 'Nombre',
      dataIndex: 'nombre',
    },
    {
      title: 'Fecha',
      dataIndex: 'Fecha',
    },
    {
      title: 'Tamaño',
      dataIndex: 'Tamanio_kb',
      render: t => `${t} KB`
    },
    {
      title: 'Borrar',
      key: 'delete',
      align: 'center',
      render: (_, record) =>
        <Button
          type="tertiary"
          icon={<RKDIcons.Delete/>}
          onClick={()=>onDelete(record)}
        />,
    }
  );
  return columns;
};

const Datos = ({api}) => {
  const history = useHistory();
  const [advice, setAdvice] = useState(null);
  const [upload, setUpload] = useState(false);
  const [alert, setAlert] = useState(false);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [files, setFiles] = useState([]);

  const fetchFiles = useCallback(() => {
    setFetchingFiles(true);
    api.fetchFiles()
    .then(r=> setFiles(r.body))
    .catch(()=>setFiles([]))
    .finally(()=>setFetchingFiles(false));
  }, [api]);

  useEffect(() => {
    fetchFiles();
    api.fetchProductInfo()
    .then(r=> r.body && setAdvice(JSON.parse(r.body)?.recorda))
    .catch();
  }, [api, fetchFiles]);

  const onDelete = (file) => {
    api.deleteFile({nombre: file.nombre})
    .then(fetchFiles)
    .catch(() => { message.error(`No se pudo eliminar el archivo ${file.nombre}`) });
  };
  return (
    <Layout className="datos-layout">
      <Content>
        <div className="datos-header">
          <div className="title">Datos</div>
          <Button
            icon={<RKDIcons.UploadLight/>}
            onClick={()=>setUpload(true)}
          >Cargar datos</Button>
        </div>
        {alert &&
          <Alert
            closable
            afterClose={()=>setAlert(false)}
            className="datos-alert"
            showIcon
            icon={<RKDIcons.UploadSuccess/>}
            message="¡Tus datos fueron cargados!"
            description={<>
              <div>Podés continuar con la visualización de tus datos en <span className="datos-alert-bold">Predecir</span>.</div>
              <div><Button type="tertiary" onClick={()=>history.push('/predict')}>Ir a predecir</Button></div>
            </>}
          />
        }
        <div className="subtitle">Datos cargados</div>
        <Table
          rowKey="nombre"
          locale={{emptyText: () => (<>
              <div className="datos-records-empty-title">Acá encontrarás el historial de tus datos cargados.</div>
              <div className="datos-records-empty-text">Presioná “Cargar datos” para comenzar.</div>
            </>)}}
          loading={fetchingFiles}
          columns={columns(onDelete)}
          dataSource={files}
        />
        <Spin spinning={!advice}>
          <Card
            title="Restricciones de subida de archivos"
            body={advice}
            icon={<RKDIcons.Exclaim/>}
            length={11}
          />
        </Spin>
        <UploadModal
          visible={upload}
          close={()=>setUpload(false)}
          success={()=>{setUpload(false); setAlert(true); fetchFiles();}}
        />
      </Content>
    </Layout>
  );
};

export default withAPI(Datos);
