import React from 'react';
import { Layout } from 'antd';
import RDLogo from 'assets/rkdps-logo.svg';
import SiderMenu from './SiderMenu/SiderMenu'

import './MainLayout.scss';

const { Sider, Content } = Layout;

const MainLayout = ({ children }) => {
  return (
    <Layout className="app-layout">
      <Sider className="app-layout-sidebar" width={"15%"}>
        <img src={RDLogo} alt="Logo Rocking Data" className="sidebar-logo"/>
        <SiderMenu/>
      </Sider>
      <Content className="app-layout-content">
        {children}
      </Content>
    </Layout>
  );
};

export default MainLayout;
