const APIPrototype = {
  withAuthUser(authToken) {
    this.authToken = authToken;
    return this;
  },

  getJSONHeaders() {
    return {
      'Content-Type': 'application/json',
    };
  },

  getAuthHeaders() {
    return {
      Authorization: `Bearer ${this.authToken}`,
    };
  },

  getAuthJSONHeaders() {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authToken}`,
    };
  },

  handleResponse(response) {
    function handle(r) {
      console.debug('Raw response:', r);
      if (r && !r.ok) {
        return r.text().then(() => {
          return Promise.reject(`${r.status}-${r.statusText}`);
        });
      };
      if (r.status === 204) return r;
      return r.json();
    }
    return handle(response);
  },

  handleBlob(response) {
    return response.blob();
  },
};

export { APIPrototype };
