import React from 'react';
import { Form, Input } from 'antd';
import { Button, Modal } from 'modules/core/components';

import './ResetPasswordModal.scss';

const { Item: FormItem } = Form;

const confirmPasswordValidator = (confirm, password) => {
  return confirm && confirm !== password
    ? Promise.reject(new Error('Las contraseñas ingresadas no coinciden'))
    : Promise.resolve();
};

const ResetPasswordModal = ({ visible, close, onReset }) => {
  return (
    <Modal destroyOnClose={true} visible={visible} onCancel={close}>
      <div className="resetpass-modal-title">Reiniciar contraseña</div>
      <div className="resetpass-modal-subtitle">Ingrese los datos para generar su nueva contraseña.</div>
      <Form
        className="resetpass-form"
        layout="vertical"
        hideRequiredMark={true}
        onFinish={onReset}
      >
        <FormItem
          name="username"
          rules={[{ required: true, message: 'Ingrese su correo electrónico' }]}
        >
          <Input
            className="resetpass-form-input"
            placeholder="Correo electrónico" />
        </FormItem>
        <FormItem
          name="old_password"
          rules={[{ required: true, message: 'Ingrese su contraseña actual' }]}
        >
          <Input.Password
            className="resetpass-form-input"
            placeholder="Contraseña actual"
          />
        </FormItem>
        <FormItem
          name="new_pass"
          rules={[{ required: true, message: 'Ingrese su nueva contraseña' }]}
        >
          <Input.Password
            className="resetpass-form-input"
            placeholder="Nueva contraseña"
          />
        </FormItem>
        <FormItem
          name="new_pass_confirm"
          dependencies={['new_pass']}
          rules={[
            { required: true, message: 'Confirme su nueva contraseña' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                return confirmPasswordValidator(value, getFieldValue('new_pass'));
              }
            }),
          ]}
        >
          <Input.Password
            className="resetpass-form-input"
            placeholder="Confrimar nueva contraseña"
          />
        </FormItem>
        <Button
          className="resetpass-form-button"
          block={true}
          type="primary"
          htmlType="submit"
        >
          Reiniciar
        </Button>
      </Form>
    </Modal >
  );
};

export default ResetPasswordModal;
