import React, { useEffect, useState } from 'react';
import { withAPI } from 'services/API';
import { Layout, message, Spin } from 'antd';
import { Card, RKDIcons } from 'modules/core/components';

import './Algoritmo.scss';

const Algoritmo = ({api}) => {
  const [info, setInfo] = useState(null);
  useEffect(() => {
    api.fetchProductInfo()
    .then(r=> r.body && setInfo(JSON.parse(r.body)))
    .catch(() => { message.error("No se pudo encontrar la información") });
  }, [api]);
  return (
    <Layout className="algoritmo-layout">
      <div className="title">Mi algoritmo</div>
      <Spin spinning={!info}>
        <div className="card-row">
          <Card
            icon={<RKDIcons.List/>}
            title= "Función"
            body={info?.funcion}
          />
          <Card
            icon={<RKDIcons.Universe/>}
            title= "Universo"
            body={info?.universo}
          />
        </div>
        <div className="card-row">
          <Card
            icon={<RKDIcons.PredictB/>}
            title= "¿Qué vamos a predecir?"
            body={info?.quevamos}
          />
        </div>
        <div className="card-row">
        <Card
          icon={<RKDIcons.Decisions/>}
          title= "¿Qué decisiones vamos a potenciar?"
          body={info?.decisiones}
        />
        </div>
        <div className="card-row">
        <Card
          icon={<RKDIcons.Objective/>}
          title= "Objetivos del negocio"
          body={info?.objetivos}
        />
        </div>
      </Spin>
    </Layout>
  );
};

export default withAPI(Algoritmo);
