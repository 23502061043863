import React, { useState, useEffect, useCallback } from 'react';
import { Layout } from 'antd';
import { withAPI } from 'services/API';
import { Table } from 'modules/core/components';
import PredictRobot from './PredictRobot/PredictRobot';
import './Predecir.scss';
const {Content} = Layout;

const columns = [
    {
      title: 'Fecha',
      dataIndex: 'dia_ejecucion',
    },
    {
      title: 'Hora',
      dataIndex: 'hora_ejecucion',
    },
    {
      title: 'Estado',
      dataIndex: 'ejecucion-estado',
    },
    {
      title: 'Descripción',
      dataIndex: 'tipo-ejecucion',
    },
];

const Predecir = ({api}) => {
  const [jobRecords, setJobRecords] = useState(null);

  const fetchJobsRecords = useCallback(() => {
    api.fetchJobsRecords()
    .then(r => r.body && setJobRecords(JSON.parse(r.body)?.data))
    .catch(() => setJobRecords([]));
  }, [api]);

  useEffect(() => {
    fetchJobsRecords();
  }, [fetchJobsRecords]);

  return (
    <Layout className="predecir-layout">
      <Content>
        <div className="title">Predecir</div>
        <div className="subtitle">¡Ahora sí! A rockear con tu algoritmo</div>
        <PredictRobot onSuccess={fetchJobsRecords}/>
        <div className="predict-records">
          <div className="predict-records-title">Predicciones previas</div>
          <Table
            rowKey="id-project"
            locale={{emptyText: () => (<>
              <div className="predict-records-empty-title">Acá encontrarás el historial de tus datos predichos.</div>
              <div className="predict-records-empty-text">Presioná “Predecir datos” para comenzar.</div>
            </>)}}
            loading={!jobRecords}
            dataSource={jobRecords}
            columns={columns}
            pagination={{defaultPageSize: 5}}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default withAPI(Predecir);
