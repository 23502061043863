import React, {useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { withAPI } from 'services/API';
import { Button } from 'modules/core/components';
import robotNeutral from 'assets/animations/robot-neutral.png';
import { parseDateString } from 'utils';

import './Dashboard.scss';

const NoGraphics = () => {
  const history = useHistory();
  return (
    <div className="dashboard-no-graphics-container">
      <div className="no-graphics-robot"><img src={robotNeutral} alt="No Graphics Robot"/></div>
      <div className="no-graphics-description">
        <div className="no-graphics-title">Tu algoritmo no está listo.</div>
        <div className="no-graphics-text">¿Ya subiste tus datos? Si no es así, entrá acá.</div>
        <Button onClick={()=>history.push('/data')}>Ir a Datos</Button>
      </div>
    </div>
  );
}

const Dashboard = ({api}) => {
  const graphics = useRef(null);
  const [dashboardFetched, setDashboardFetched] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [graphicsLoaded, setGraphicsLoaded] = useState(false);
  useEffect(()=> {
    api.fetchDashboardParams()
    .then(r => {
      setDashboardFetched(true);
      const {last_update, url} = r.body ?? {};
      setLastUpdate(last_update && parseDateString(last_update, 'es-AR'));
      if(url){
        setGraphicsLoaded(true);
        embedDashboard({
          url: url,
          container: graphics.current,
          scrolling: "no",
          height: "100%",
          width: "100%",
          footerPaddingEnabled: true
        });
      }
  })}, [api]);
  return (
    <Layout className="dashboard-layout">
      <div className="dashboard-header">
        <div className="title">Dashboard</div>
        <div className="dashboard-data">
          <div className="dashboard-data-title">Última actualización</div>
          <div className="dashboard-data-update">{lastUpdate ?? "-"}</div>
        </div>
      </div>
      {dashboardFetched ?
        graphicsLoaded
          ? <div ref={graphics} className="dashboard-graphics-container"/>
          : <NoGraphics/>
        : <Spin indicator={<LoadingOutlined className="dashboard-loading" spin />}/>
      }
    </Layout>
  );
};

export default withAPI(Dashboard);
