import React, { useContext } from 'react';

const APIContext = React.createContext({ api: {} });

const withAPI = (ComponentToWrap) => (props) => {
  const context = useContext(APIContext);
  const { api } = context;
  return <ComponentToWrap api={api} {...props} />;
};

export { APIContext, withAPI };
