import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { RKDIcons } from 'modules/core/components/';

import './SiderMenu.scss';

const menuItems = [
  {
    section: "HOME",
    items: [
      {title: "Mi algoritmo", icon: RKDIcons.Code, path: "/algorithm"},
      {title: "Perfil", icon: RKDIcons.Profile, path: "/profile"},
    ]
  },
  {
    section: "FLOW",
    items: [
      {title: "Datos", icon: RKDIcons.Storage, path: "/data"},
      {title: "Predecir", icon: RKDIcons.Predict, path: "/predict"},
      {title: "Dashboard", icon: RKDIcons.Dashboard, path: "/dashboard"},
    ]
  },
  {
    section: "ADVANCED",
    items: [
      {title: "Entrenamiento", icon: RKDIcons.Dashboard, disabled: true, path: "/training"},
      {title: "Explicabilidad", icon: RKDIcons.Dashboard, disabled: true, path: "/explicability"},
    ]
  },
]

const SiderMenu = () => {
  const location = useLocation();
  // TODO: Grab base pathname to match loosely every menu entry.
  return (
    <Menu
      mode="inline"
      theme="dark"
      className="sidermenu"
      selectedKeys={[location.pathname]}
    >
      {menuItems.map(({section, items}, i) => (
        <Menu.ItemGroup
          key={section}
          className="sidermenu-section"
          title={section}
        >
          {items.map(item =>(
            <Menu.Item
              disabled={item.disabled}
              key={item.path}
              className="sidermenu-item"
              icon={<item.icon className="sidermenu-icon"/>}
            >
              <Link to={{ pathname: item.path }}>{item.title}</Link>
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      ))}
    </Menu>
  );
};

export default SiderMenu;
