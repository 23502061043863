import React from 'react';
import Icon from '@ant-design/icons';
import {ReactComponent as Code} from 'assets/icons/code.svg';
import {ReactComponent as Dashboard} from 'assets/icons/dashboard.svg';
import {ReactComponent as Profile} from 'assets/icons/profile.svg';
import {ReactComponent as Storage} from 'assets/icons/storage.svg';
import {ReactComponent as Predict} from 'assets/icons/predict.svg';
import {ReactComponent as Delete} from 'assets/icons/delete.svg';
import {ReactComponent as Upload} from 'assets/icons/upload-default.svg';
import {ReactComponent as UploadLight} from 'assets/icons/upload-mini.svg';
import {ReactComponent as UploadSuccess} from 'assets/icons/upload-success.svg';
import {ReactComponent as UploadFail} from 'assets/icons/upload-failed.svg';
import {ReactComponent as Close} from 'assets/icons/close.svg';
import {ReactComponent as List} from 'assets/icons/list-badge.svg';
import {ReactComponent as Decisions} from 'assets/icons/decisions-badge.svg';
import {ReactComponent as Universe} from 'assets/icons/universe-badge.svg';
import {ReactComponent as PredictB} from 'assets/icons/predict-badge.svg';
import {ReactComponent as Objective} from 'assets/icons/objective-badge.svg';
import {ReactComponent as Exclaim} from 'assets/icons/exclaim-badge.svg';

const generateIcon = (component, props) => <Icon component={component} {...props}/>;

const RKDIcons = {
  Code: (props) => generateIcon(Code, props),
  Dashboard: (props) => generateIcon(Dashboard, props),
  Profile: (props) => generateIcon(Profile, props),
  Storage: (props) => generateIcon(Storage, props),
  Predict: (props) => generateIcon(Predict, props),
  Delete: (props) => generateIcon(Delete, props),
  Upload: (props) => generateIcon(Upload, props),
  UploadLight: (props) => generateIcon(UploadLight, props),
  UploadSuccess: (props) => generateIcon(UploadSuccess, props),
  UploadFail: (props) => generateIcon(UploadFail, props),
  Close: (props) => generateIcon(Close, props),
  List: (props) => generateIcon(List, props),
  Decisions: (props) => generateIcon(Decisions, props),
  Universe: (props) => generateIcon(Universe, props),
  PredictB: (props) => generateIcon(PredictB, props),
  Objective: (props) => generateIcon(Objective, props),
  Exclaim: (props) => generateIcon(Exclaim, props),
};

export default RKDIcons;
