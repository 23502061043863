import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Row, Col, Layout, message } from 'antd';
import { withAPI } from 'services/API';
import { localToken } from 'utils';
import RDLogo from 'assets/rkdps-logo.svg';
import LoginForm from './LoginForm/LoginForm';
import ResetPasswordModal from './ResetPasswordModal/ResetPasswordModal';

import './Login.scss';

const ResetPasswordError = () => (
  <span>No se pudo resetear la contraseña
    <br />
    <a class="login-reset-error" href='mailto:powersuite@rockingdata.com.ar'>
      Contáctese
    </a>
  </span>
);

const Login = ({ api }) => {
  const history = useHistory();
  const [logging, setLogging] = useState(false);
  const [resetSession, setResetSession] = useState(false);
  const login = (token) => {
    api.withAuthUser(token);
    localToken.set(token);
    history.push('/algorithm');
  };
  const onSubmit = async (loginData) => {
    setLogging(true);
    const response = await api.login(loginData)
    .catch(() => { message.error("Usuario o contraseña incorrectos") });
    setLogging(false);
    if (response?.body?.session) setResetSession(response.body.session);
    if (response?.body?.token) login(response.body.token);
  };
  const onReset = async (resetData) => {
    const response = await api.resetPassword({ ...resetData, session: resetSession })
    .catch(() => { message.error(<ResetPasswordError/>, 10) });
    if (response?.respuesta?.AuthenticationResult.AccessToken)
      login(response.respuesta.AuthenticationResult.AccessToken);
  };
  return (
    <Layout className="login-layout">
      <Row justify="center" align="middle" className="login-header">
        <img src={RDLogo} alt="Logo Rocking Data" className="login-header-logo" />
      </Row>
      <Row className="login-content">
        <Col className="login-content-form" span={10} offset={7}>
          <LoginForm loading={logging} onSubmit={onSubmit} />
        </Col>
      </Row>
      <ResetPasswordModal
        visible={!!resetSession}
        close={() => setResetSession(false)}
        onReset={onReset}
      />
    </Layout>
  );
};

export default withAPI(Login);

