import React from 'react';
import { Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import Lottie from 'react-lottie-player';
import robotAnimation from 'assets/animations/robot-login.json'
import { Button } from 'modules/core/components';

import './LoginForm.scss';

const { Item: FormItem } = Form;

const LoginForm = ({ onSubmit, loading }) => {
  return (
    <Form
      className="login-form"
      layout="vertical"
      hideRequiredMark={true}
      onFinish={onSubmit}
    >
      <div className="login-form-header">
        <div className="login-form-title">Iniciar sesión</div>
        <Lottie
          animationData={robotAnimation}
          play
          style={{ width: 200, height: 200 }}
        />
      </div>
      <FormItem
        name="username"
        rules={[{ required: true, message: 'Ingrese su correo electrónico' }]}
      >
        <Input placeholder="Correo electrónico" />
      </FormItem>
      <FormItem
        name="password"
        rules={[{ required: true, message: 'Ingrese su contraseña' }]}
      >
        <Input.Password
          placeholder="Contraseña"
          iconRender={visible => (visible ? <EyeTwoTone twoToneColor="#DE0000" /> : <EyeInvisibleOutlined />)}
        />
      </FormItem>
      <FormItem>
        <Button
          className="login-form-button"
          block={true}
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Ingresar
        </Button>
      </FormItem>
      <div className="login-form-footer">
        <a href="mailto:powersuite@rockingdata.com.ar?subject=Olvidé mi contraseña">
          <Button type="tertiary-light">¿Olvidaste tu contraseña?</Button>
        </a>
      </div>
    </Form>
  )
};

export default LoginForm;
