import React, { useState } from 'react';
import { message, Spin, Upload } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withAPI } from 'services/API';
import { Modal, Button, RKDIcons } from 'modules/core/components';
import './UploadModal.scss';

const UploadModal = ({ api, visible, close, success }) => {
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const clearState = () => {
    setIsUploading(false);
    setUploaded(false);
    setFileList([]);
  }

  const onUpload = (file) => {
    const fileAsForm = new FormData();
    fileAsForm.append('file', file);
    setIsUploading(true);
    api.uploadFile({ file: fileAsForm })
    .then(() => setUploaded(true))
    .catch(() => { message.error(`Error al subir el archivo`) })
    .finally(()=> setIsUploading(false))
  }

  const UploadFile = () => {
    const uploadParams = {
      accept: '.csv,.xlsx,.xls',
      showUploadList: false,
      beforeUpload: (file) => {
        setFileList([file]);
        onUpload(file);
        return false;
      },
    };
    return (
      <>
        <div className="upload-modal-title">Carga de datos</div>
        <div className="upload-modal-subtitle">
          Recordá revisar las restricciones para la subida de tus archivos
      </div>
        <div className="upload-modal-btns">
          <Button type="tertiary" onClick={close}>Cancelar</Button>
          <Upload className="upload-file-container" {...uploadParams}>
            <Button size="medium" block={true} className="upload-file-button">
              <RKDIcons.UploadLight />
              <span className="upload-file-text">
                Elegir archivo
            </span>
            </Button>
          </Upload>
        </div>
      </>
    )
  };

  const Uploading = () => (
    <div className="upload-modal-uploading">
      <div className="upload-modal-title ">Cargando el archivo</div>
      <Spin indicator={<LoadingOutlined className="upload-modal-spin" spin />} />
    </div>
  );

  const Result = () => (
    <>
      <div className="upload-modal-title ">Archivo cargado</div>
      <div className="upload-modal-file"><RKDIcons.UploadSuccess />{fileList[0].name}</div>
      <div className="upload-modal-btns-right">
        <Button size="medium" onClick={success}>Finalizar</Button>
      </div>
    </>
  );

  const UploadElement = () => {
    if (isUploading)
      return <Uploading />;
    else if (uploaded)
      return <Result />;
    return <UploadFile />;
  }

  return (
    <Modal
      className="upload-modal"
      destroyOnClose={true}
      visible={visible}
      onCancel={close}
      afterClose={clearState}
    >
      <UploadElement />
    </Modal>
  );
};

export default withAPI(UploadModal);
