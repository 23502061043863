import React from 'react';
import { Table as TableAnt } from 'antd';

import './Table.scss';

const Table = ({
  pagination,
  className = "",
  ...props
}) => {
  return (
    <TableAnt
      {...props}
      className={`rkdtable ${className}`}
      pagination={{ ...pagination, position: ['bottomRight'] }}
    />
  );
};

export default Table;
