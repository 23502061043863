import React from 'react';
import { Alert as AntAlert } from 'antd';
import { RKDIcons } from 'modules/core/components';


import './Alert.scss';

const Alert = ({
  type = "default",
  className = "",
  ...props
}) => (
  <AntAlert
    closeText={<RKDIcons.Close/>}
    {...props}
    className={`rkdalert ${className}`}
  />
 );

export default Alert;
