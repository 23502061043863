const TOKEN = {
  KEY: 'RKD::Token',
  set(token) {
    return localStorage.setItem(this.KEY, token);
  },
  remove() {
    return localStorage.removeItem(this.KEY);
  },
  get() {
    return localStorage.getItem(this.KEY);
  },
};

export default TOKEN;
